/**
 * EVENT_INFO_LIST
 * 事件类型列表字段
 */
export const EVENT_INFO_LIST = [
    {
        title: '事件类型',
        isTrue: true
    },
    {
        title: '模板名称',
        isTrue: true
    },
    {
        title: '模板编号',
        isTrue: true
    },
    {
        title: '邮件接收角色',
        isTrue: true
    },
    {
        title: '邮件抄送角色',
        isTrue: true
    },
    {
        title: '创建人',
        isTrue: true
    },
    {
        title: '更新人',
        isTrue: true
    },
    {
        title: '操作',
        isTrue: true
    }
]
